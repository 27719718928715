import { createState, State, useState } from '@hookstate/core';
import branding, { setBrandingLanguage, Branding } from '../branding/branding';

const localStorageLanguageKey = 'event-cloud-selfservice-language'



export function getActiveLanguage(): string {
  var language;
  try {
    language = localStorage.getItem(localStorageLanguageKey)
  } catch (securityError) {
    // No Cookies! Oh noes! Nothing works!
  }
  if (!language) {
    language = navigator.language.slice(0, 2)
  }

  const availableLanguages = branding.availableLanguages
  if (availableLanguages.indexOf(language) > -1) {
    return language
  } else {
    return availableLanguages[0]
  }
}
interface StateValues {
  availableLanguages: string[]
  language: string
  strings: Branding
}

let strings = {
  availableLanguages: branding.availableLanguages,
  language: getActiveLanguage(),
  strings: branding
}

const useWrapState = ((state: State<StateValues>) => {
  return ({
    setLanguage: (language: string) => {
      setBrandingLanguage(language)
      state.set(prevState => {
        if (prevState.availableLanguages.indexOf(language) > -1) {
          prevState.language = language
        }
        try {
          localStorage.setItem(localStorageLanguageKey, prevState.language)
        } catch (error) {
          // No local storage :( can not save language
        }
        return prevState
      })
    },
    getLanguage: () => {
      return state.value.language
    },
    getStrings: () => {
      return state.value.strings
    },
    getAvailableLanguages: () => {
      return branding.availableLanguages
    }
  })
})
const state = createState(strings)
export const useLanguageState = () => useWrapState(useState(state))

export function replacePlaceholder(text: string, replacements: { [placeholderKey: string]: string }) {
  if (!replacements || text.indexOf("${") < 0)
    return text
  let newText = text
  for (const key in replacements) {
    if (replacements[key])
      newText = newText.split("${​​" + key + "}​​").join(replacements[key])
  }
  if (newText.indexOf("${") > 0)
    newText = newText.substring(0, text.indexOf("${") - 2)
  return newText
}
