import download from 'downloadjs';
import branding from "../branding/branding"
import { getAppUrl, getProtocolAndHost } from "../environments"
import { getActiveLanguage } from "../globalStates/LanguageState"
import { getTimezoneOffest } from "../utils/DateUtils"
import { xhr } from "./api"

function getDefaultParams() {
    const defaultParams = {
        topic: branding.configuration.topicName,
        os: "web",
        appUrl: getAppUrl(),
        lang: getActiveLanguage(),
        language: getActiveLanguage(),
        apiVersion: "36",
        timezoneOffset: getTimezoneOffest().toString(),
        userLang: "en-US",
    }
    switch (defaultParams.lang) {
        case "en":
            defaultParams.userLang = "en-US"
            break
        case "de":
            defaultParams.userLang = "de-DE"
            break
        default:
    }
    return defaultParams
}

const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
}

export async function fetchDataWebService(path: string, params: object | null, signal?: AbortSignal, customHeaders?: object) {
    const protocolAndHost = getProtocolAndHost()

    let combinedParams = params ? { ...getDefaultParams(), ...params } : getDefaultParams()

    var formBody = new URLSearchParams()
    Object.entries(combinedParams).forEach((value) => {
        formBody.append(value[0], value[1])
    })

    defaultHeaders["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8"

    const headers = { ...defaultHeaders, ...customHeaders }

    const req = fetch(`${protocolAndHost}/webservice${path}`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: headers,
        body: formBody,
        signal: signal
    })

    const resp = await req
    if (resp.status === 400) {
        // Read the reason from the response
        const jsonResp = await resp.text()
        throw Error(`Data access failed. ${jsonResp || ''}`)
    } else if (resp.status === 403) {
        throw Error("Forbidden.")
    } else {
        if (resp.status !== 200) {
            throw Error("Data access failed.")
        }
    }
    const jsonResp = await resp.text()
    return jsonResp
}

export async function uploadDataWebService(path: string, file: File, onProgress: (progress: number) => void) {
    const protocolAndHost = getProtocolAndHost()
    const defaultParams = getDefaultParams()

    const formPath = `${path}&topic=${defaultParams.topic}&apiVersion=${defaultParams.apiVersion}`

    const formData = new FormData()
    formData.append('file', file)
    formData.append('fileContentLength', `${file.size}`)

    const call = xhr(`${protocolAndHost}/webservice${formPath}`, {
        method: "POST",
        body: formData,
        onProgress
    })

    const resp = await call
    if (resp.status === 400) {
        // Read the reason from the response
        throw Error(`Upload failed. ${resp.responseText || ''}`)
    } else if (resp.status === 403) {
        throw Error("Forbidden.")
    } else {
        if (resp.status !== 200) {
            throw Error("Upload failed.")
        }
    }
    return resp.responseText
}

export async function downloadFile(path: string, filename: string, signal?: AbortSignal) {
    const req = fetch(path, {
        method: "GET",
        mode: "cors",
        signal: signal
    })

    const response = await req

    if (response.status !== 200) {
        throw Error("Data access failed.")
    }

    const reader = response?.body?.getReader();
    const buf = await reader?.read()
    if (buf && buf.value) {
        download(buf.value, filename)
    }
}

export async function fetchDataRest(
    path: string,
    queryParams: object | null,
    method: "GET" | "POST" = "GET",
    requestBody?: object,
    context: string = "/rest",
    responseCallback?: Function,
    customHeaders?: object
) {
    const protocolAndHost = getProtocolAndHost()
    //const accessDataString = localStorage.getItem(localStorageKey) // for real login stuff later on
    const token = null //accessDataString ? JSON.parse(accessDataString).jwtToken : null

    const accessHeaders = {
        beConnectionToken: token ? token : branding.configuration.sotAccessToken,
    }

    defaultHeaders["Content-Type"] = "application/json"

    const headersMerged = { ...defaultHeaders, ...customHeaders }

    const headers = { ...accessHeaders, ...headersMerged }

    function makeParams(params: object | null): string {
        let combinedParams = getDefaultParams()
        if (params) {
            combinedParams = { ...combinedParams, ...params }
        }
        var paramsValue = new URLSearchParams()
        Object.entries(combinedParams).forEach((value) => {
            paramsValue.append(value[0], value[1])
        })
        return "?" + paramsValue
    }

    const req = fetch(protocolAndHost + `${context}${path}${makeParams(queryParams)}`, {
        method: method,
        mode: "cors",
        cache: "no-cache",
        headers: headers,
        body: method !== "GET" && requestBody ? JSON.stringify(requestBody) : null,
    })

    const resp = await req

    if (responseCallback) {
        return await responseCallback(resp)
    } else {
        if (resp.status >= 200 && resp.status < 300 && resp.status !== 204) {
            return await resp.json()
        } else if (resp.status === 401) {
            throw Error("Unauthoried.")
        } else if (resp.status === 403) {
            throw Error("Forbidden.")
        }
        else {
            let errorMessage = ""
            try {
                const responseJson = await resp.text()
                errorMessage = responseJson || resp.statusText
            } catch {
                try {
                    const responseText = await resp.text()
                    errorMessage = responseText || resp.statusText
                } catch {
                    errorMessage = resp.statusText
                }
            }
            throw Error(errorMessage)
        }
    }
}