export function stripControlCharacters(str: string) {
    if (isNullOrEmpty(str)) return str
    return str.replace(/\n/g, '\\n').replace(/\p{Cc}/gu, '')
}

export function restoreLineBreaks(str: string) {
    if (isNullOrEmpty(str)) return str
    return str.replace(/\\n/g, '\n')
}

export function isNullOrEmpty(string: string) {
    return !string || string === '';
}

export function concatStringWithSeparator(base: string, string: string, separator: string, stringPrefix?: string) {
    return string ? `${base || ''}${isNullOrEmpty(base) ? '' : separator}${stringPrefix || ''}${string}` : base;
}

export function compareStrings(s1: string, s2: string) {
    return ('' + s1).localeCompare('' + s2)
}

export function stripNull(str: string) {
    return (str === null) ? undefined : str
}