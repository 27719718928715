import { createState, useState } from '@hookstate/core'
import branding from "../branding/branding"
import { fetchDataRest } from '../backendServices/backendServices'

let initialState = {
    serviceKeys: {
        headerVideo: 0,
        headerGraphic: 0,
        multimedia: 0,
        logo: 0,
        socialMedia: 0,
        iframe: 0,
        consent: 0,
        link: 0,
        products: 0,
        trademarks: 0,
        contactPartner: 0,
        news: 0,
        keywords: 0,
        category: 0,
        licence: 0,
        showroomPrivate: 0,
        showroomPublic: 0,
        goodies: 0,
        jobAd: 0,
        printOrganizationName: 0,
        printAddress: 0,
        printAddressCategoryDirectory: 0,
        printContact: 0,
        printContactCategoryDirectory: 0,
        printLogo: 0,
        printLogoExhibitorDirectory: 0,
        printLogoCategoryDirectory: 0,
        printDescription: 0,
        printAd: 0,
        eventDateRemoteLiveStream: 0,
        eventDateMasterClass: 0,
        eventDatePreRecorded: 0,
        eventDateRoundTable: 0,
        eventDate: 0
    },
    topicName: "",
    organizationId: ""
}

export interface ServiceKeys {
    headerVideo?: number
    headerGraphic?: number
    multimedia?: number
    logo?: number
    socialMedia?: number
    iframe?: number
    link?: number
    consent?: number
    products?: number
    trademarks?: number
    news?: number
    keywords?: number
    category?: number
    licence?: number
    contactPartner?: number
    showroomPrivate?: number
    showroomPublic?: number
    goodies?: number
    jobAd?: number
    printOrganizationName?: number
    printAddress?: number
    printAddressCategoryDirectory?: number,
    printContact?: number
    printContactCategoryDirectory?: number,
    printLogo?: number
    printLogoExhibitorDirectory?: number
    printLogoCategoryDirectory?: number
    printDescription?: number
    printAd?: number
    eventDateRemoteLiveStream?: number
    eventDateMasterClass?: number
    eventDatePreRecorded?: number
    eventDateRoundTable?: number
    eventDate?: number
}

interface ConfigData {
    serviceKeys: ServiceKeys
    topicName: string
    organizationId: string
}

export interface ConfigDataState {
    init(exhibitorId: string, accessToken: string): Promise<any>
    getServiceKeys(): ServiceKeys
}

const configDataState = createState<ConfigData>(initialState)

async function loadConfigData(exhibitorId: string, accessToken: string) {
    const params: any = {
        "accessToken": accessToken
    }
    return await fetchDataRest(`/servicekeys/simpleaccesstoken/topic/${branding.configuration.topicName}/organizationId/${exhibitorId}`, params, "GET")
}

export function useConfigDataState(): ConfigDataState {
    const state = useState(configDataState)

    return ({
        init(exhibitorId: string, accessToken: string) {
            const cfgData = loadConfigData(exhibitorId, accessToken)
            cfgData.then((result) => state.set({ ...result }))
            return cfgData
        },

        getServiceKeys() {
            return state.serviceKeys.get() || {}
        }
    })
}

export function hasServiceKeys(serviceKey: number | undefined) {
    return serviceKey ? serviceKey > 0 : false
}
